import $ from "jquery";

const $doc = $(document);
let open = `<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
  <line x1="9.5" y1="0.5" x2="9.5" y2="19.5" stroke="#262D33" stroke-width="3"/>
  <line x1="19" y1="10" y2="10" stroke="#262D33" stroke-width="3"/>
</svg>`;
let close = `<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
  <line x1="16.2179" y1="3.28258" x2="2.78286" y2="16.7176" stroke="#A5750E" stroke-width="3"/>
  <line x1="16.2177" y1="16.7174" x2="2.78263" y2="3.28237" stroke="#A5750E" stroke-width="3"/>
</svg>`;
function addIcon() {
  let item = $(".accordion__title-js .wp-block-button__link.wp-element-button");
  item.addClass("accordion__button")
  let span1 = $("<span class='open'>");
  let span2 = $("<span class='close hidden'>");
  span1.append(open);
  span2.append(close);
  item.append(span1, span2);
}

$doc.ready(addIcon);
